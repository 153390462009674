import { axiosInstance } from "../../axios";
const GETSearchAllPostcode = `/api/Postcode/SearchAllPostcode`;
const POSTCreatePostcode = `/api/Postcode/CreatePostcode`;
const DELETEPostcode = `/api/Postcode/Delete?id=`;

const SearchAllPostcode = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(
      GETSearchAllPostcode
    );
    let data = parseList(response);
    return data;
  } catch (error) {
    return [];
  }
};

const RemovePostcode = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.delete(
      DELETEPostcode + id
    );
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const SavePostcode= async function(postcode) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreatePostcode, postcode);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const parseError = error => {
  if (error.response.data.Errors){
    let stringMessage = error.response.data.Errors.join(" <br/> ");
    error.response.data.Message += "<br/>" + stringMessage;
    return error.response.data;
  } else{
    return error.response.data;
  }
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const postcodeData = {
  SearchAllPostcode,
  SavePostcode,
  RemovePostcode
};
