<template>
<vx-card title="Postcodes">
    <template slot="actions">
        <vs-button @click="editSelected">Add</vs-button>
    </template>
    <vs-table hoverFlat stripe :data="data" v-if="data.length">
        <template #thead>
            <vs-th sort-key="tuple4">Preference</vs-th>
            <vs-th sort-key="tuple1">Suburb</vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in data" :key="index" :data="tr">
                <vs-td>{{tr.tuple4}}</vs-td>
                <vs-td>{{tr.tuple1}} {{tr.tuple2}} {{tr.tuple3}}</vs-td>
                <vs-td>
                    <vs-button @click="handleRemove(tr.id)" color="danger">Delete</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description" ></NoData>
</vx-card>
</template>

<script>
import AddLocalPostcode from './AddLocalPostcode';
import {
    postcodeData
} from "../../store/api/postcode"

export default {
    data() {
        return {
            selectedPass: undefined,
            openAddWindow: false,
            data: [],
            noData: {
              title: "No Records Found",
            }
        }
    },
    components: {
        AddLocalPostcode
    },
    async created() {
        await this.loadSeasonPass()
    },
    methods: {
        async loadSeasonPass() {
            this.data = await postcodeData.SearchAllPostcode();
        },
        editSelected() {
            this.$router.push("localpostcode/add/");
        },
        handleSelectedPass() {
            this.selectedPass = value;
        },
        async handleRemove(id) {
            if (!confirm("are you sure ? ")) {
                return;
            }

            const apiResult = await postcodeData.RemovePostcode(id);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Deleted",
                color: "success",
                position: "top-center"
            });
            this.loadSeasonPass();
        }
    }
}
</script>
